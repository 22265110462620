import * as anchor from "@project-serum/anchor";

export const programId = new anchor.web3.PublicKey(
  "cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ"
);

// export const NETWORK = "mainnet";
// // export const NETWORK = "devnet";

// export const config = new anchor.web3.PublicKey(
//   "9bfgrt6WfPCfneVsDWJdCzdCuFYpeGdBP5973SbwQbk8"
// );
// export const configUUID = "9bfgrt";

// export const candyMachineOwner = new anchor.web3.PublicKey(
//   "Lum9QdS3MUyGLvLpJgvhHjAZ5nTWpjsxRA2u5Nnkutz"
// );

export const NETWORK = "mainnet";
// export const NETWORK = "devnet";

export const config = new anchor.web3.PublicKey(
  "CPCHdAXtfEw9JsAr7Q6py6Wu39CiwX3FxwHVhNDjn13h"
);
export const configUUID = "CPCHdA";

export const candyMachineOwner = new anchor.web3.PublicKey(
  "AxFuniPo7RaDgPH6Gizf4GZmLQFc4M5ipckeeZfkrPNn"
);
