import React from "react";
import "./App.css";
import { WalletProvider, useWallet } from "./providers/useWallet";
import { mintNFT } from "./actions/mintNFT";
import { CandyMachineProvider, useCandyMachine } from "./providers/useCandyMachine";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

function App() {
  return (
    <div className="App">
      <WalletProvider>
        <CandyMachineProvider>
          <BuyScreen />
        </CandyMachineProvider>
      </WalletProvider>
    </div>
  );
}

function BuyScreen() {
  const { connection, wallet, connectWallet } = useWallet();
  const { candyMachine, findCandyMachine } = useCandyMachine();

  let displayRemaining = "";
  if (connection && wallet && !candyMachine) {
    findCandyMachine(connection, wallet);
    console.log(candyMachine);
  }
  if (candyMachine) {
    displayRemaining =
      `${
        candyMachine.data.itemsAvailable.toNumber() -
        candyMachine.itemsRedeemed.toNumber()
      }/${candyMachine.data.itemsAvailable.toNumber()}`;
  }
  // console.log(candyMachine);
  // console.log("***************");
  // console.log("authority: ",candyMachine.authority.toBase58());
  // console.log("startTime: ",new Date(candyMachine.data.goLiveDate.toNumber()*1000));
  // console.log("redeemed : ", candyMachine.itemsRedeemed.toNumber());
  // console.log("supply : ", candyMachine.data.itemsAvailable.toNumber());
  // console.log("price : ", candyMachine.data.price.toNumber()/1000000000);
  
  const showToast = (txnId:string) => {
    toast(
      <a href={`https://solscan.io/tx/${txnId}`} style={{ color: "orange" }}>
        View on Solscan {txnId.slice(0, 5)}...{txnId.slice(txnId.length-5)}
      </a>,
      {
        className: "toastFont",
      }
    );
  };

  return (
    <header className="App-header">
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="Container">
        <h1>Degods</h1>
        <div className="grid2x2">
          {(!wallet || !wallet.connected) && (
            <>
              <div
                onClick={() => {
                  connectWallet("sollet");
                }}
              >
                Sollet
              </div>
              <div
                className="phantom"
                onClick={() => {
                  connectWallet("phantom");
                }}
              >
                Phantom
              </div>
            </>
          )}
          {wallet && wallet.connected && (
            <>
              <div
                onClick={() => {
                  mintNFT(connection, wallet, showToast, findCandyMachine);
                }}
              >
                Mint ◎
                {(candyMachine && candyMachine.data.price.toNumber() / 1000000000) || "?"}
                <br></br>
                {displayRemaining || "?/?"}
              </div>
            </>
          )}
        </div>
      </div>
    </header>
  );
}
export default App;
